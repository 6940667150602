import axios from "axios";

const disqavaApi = axios.create({
  baseURL: "https://meqers.com/data-extraction-native/api/",
  // baseURL: "https://disqava.com/data-extraction-native/api/",
  // baseURL: "http://localhost/data-extraction-native/api/",
});

disqavaApi.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("token")}`;

export default disqavaApi;
